import React, { useState, useEffect } from 'react';
import { graphql, PageProps, HeadFC } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { HeaderAndFooterLayout } from '../layouts/HeaderAndFooter';
import Masonry from 'react-responsive-masonry';
import SEO from '../components/SEO';
import { css } from '../../styled-system/css';

const GalleryTemplate: React.FC<PageProps> = ({ data, params }) => {
  const queryData = data as Query;
  const { category } = params;
  const [colsAmount, setColsAmount] = useState(1);

  useEffect(() => {
    const resizeListener = () => {
      const newWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setColsAmount(newWidth > 900 ? 3 : newWidth > 600 ? 2 : 1);
    };
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  // Filtrer les images selon la catégorie
  const correctImagesToDisplay = queryData.allContentfulPortfolioImages.edges.filter(
    ({ node }) => node.category === category
  );

  return (
    <HeaderAndFooterLayout>
      <h1 className={css({ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold', margin: '20px 0' })}>
        Galerie photo {category}
      </h1>
      <Masonry columnsCount={colsAmount} gutter="5px">
        {correctImagesToDisplay.map(({ node }) => (
          <GatsbyImage
            key={node.id}
            className={css({ width: '100%' })}
            image={getImage(node.image.gatsbyImageData) as IGatsbyImageData}
            alt={node.image.title || 'Portfolio Image'}
          />
        ))}
      </Masonry>
    </HeaderAndFooterLayout>
  );
};

// Query pour récupérer les images en fonction de la catégorie
export const query = graphql`
  query ($category: String) {
    allContentfulPortfolioImages(filter: { category: { eq: $category } }) {
      edges {
        node {
          id
          category
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
            title
            filename
          }
        }
      }
    }
  }
`;

type Query = {
  allContentfulPortfolioImages: {
    edges: {
      node: {
        id: string;
        category: string;
        image: {
          gatsbyImageData: IGatsbyImageData;
          filename: string;
          title: string;
        };
      };
    }[];
  };
};

export default GalleryTemplate;

// Définition du composant Head pour chaque page de catégorie
export const Head: HeadFC = ({ params }) => {
  const { category } = params;

  // Génération du titre et de la description SEO en fonction de la catégorie
  const title = `Galerie photo ${category} - Découvrez nos prestations photographiques`;
  const description = `Explorez la galerie photo dédiée à ${category} par Digit Universe Photographie. Découvrez nos prestations en photographie de grossesse, nouveau-nés, familles, couples, et événements. Immortalisez vos souvenirs avec créativité et passion.`;

  return (
    <SEO
      title={title}
      description={description}
      robotsContent="index, follow"
    />
  );
};
